/**** Colors ****/
:root {
    --wb-background-blue: #c9e9fc;
    --wb-background-gray: #bfccd4;
    --wb-background-gray-dark: #79909a;
    --wb-background-gray-light: #eef0f2;
    --wb-background-white: #ffffff;
    --wb-black: #080d0d;
    --wb-blue: #008bd1;
    --wb-c1-50: #80c5e8;
    --wb-c2: #009fdc;
    --wb-c2-50: #80cfed;
    --wb-c3: #45b6e7;
    --wb-c3-50: #a2daf3;
    --wb-c4: #8cd2f7;
    --wb-c4-50: #c5e8fb;
    --wb-c5-50: #e4f4fd;
    --wb-secondary-azure: #7ac2ba;
    --wb-secondary-azure-50: #bde0dc;
    --wb-secondary-brown-50: #ac8c8e;
    --wb-secondary-dr-gray-50: #bcc7cc;
    --wb-secondary-green: #075a2a;
    --wb-secondary-green-50: #83ad95;
    --wb-secondary-olive: #a0aa77;
    --wb-secondary-olive-50: #cfd4bb;
    --wb-secondary-orange: #e74e00;
    --wb-secondary-orange-50: #f3a780;
    --wb-secondary-purple: #7a1350;
    --wb-secondary-purple-50: #bd89a8;
    --wb-secondary-red-50: #da8087;
    --wb-secondary-roze: #da8e93;
    --wb-secondary-roze-50: #ecc6c9;
    --wb-secondary-violet: #251f4a;
    --wb-secondary-violet-50: #7d6d8f;
    --wb-secondary-yellow-50: #ffe280;
    --wb-secondary-zc1-50: #848686;
    --wb-secondary-zc3-50: #728087;
    --wb-secondary-zc4-50: #80afbd;
    --wb-secondary-zc5-50: #75b3d1;
    --wb-signal-green: #00a03c;
    --wb-signal-red: #b5000f;
    --wb-signal-yellow: #ffc500;
    --wb-zc1-50: #848686;
    --wb-zc2: #1a2a2f;
    --wb-zc2-50: #8d9597;
    --wb-zc3: #194654;
    --wb-zc3-50: #8ca3aa;
    --wb-zc4: #005e7a;
    --wb-zc4-50: #80afbd;
    --wb-zc5: #0075a3;
    --wb-zc5-50: #80bad1;
}

/* close :root */

/**** Fonts ****/
:root {
    /** expressive-heading1 **/
    --wb-expressive-heading1-font-family: Roboto;
    --wb-expressive-heading1-font-size: 60px;
    --wb-expressive-heading1-font-weight: 100;
    --wb-expressive-heading1-line-height: 0px;
    --wb-expressive-heading1-color: rgba(0, 93, 118, 1);
    /** expressive-heading2 **/
    --wb-expressive-heading2-font-family: Roboto;
    --wb-expressive-heading2-font-size: 48px;
    --wb-expressive-heading2-font-weight: 300;
    --wb-expressive-heading2-line-height: 0px;
    --wb-expressive-heading2-color: rgba(0, 93, 118, 1);
    /** expressive-heading3 **/
    --wb-expressive-heading3-font-family: Roboto;
    --wb-expressive-heading3-font-size: 32px;
    --wb-expressive-heading3-font-weight: 700;
    --wb-expressive-heading3-line-height: 0px;
    --wb-expressive-heading3-color: rgba(0, 93, 118, 1);
    /** expressive-heading4 **/
    --wb-expressive-heading4-font-family: Roboto;
    --wb-expressive-heading4-font-size: 28px;
    --wb-expressive-heading4-font-weight: 400;
    --wb-expressive-heading4-line-height: 0px;
    --wb-expressive-heading4-color: rgba(0, 93, 118, 1);
    /** expressive-heading5 **/
    --wb-expressive-heading5-font-family: Roboto;
    --wb-expressive-heading5-font-size: 24px;
    --wb-expressive-heading5-font-weight: 400;
    --wb-expressive-heading5-line-height: 0px;
    --wb-expressive-heading5-color: rgba(0, 0, 0, 1);
    /** expressive-introtext **/
    --wb-expressive-introtext-font-family: Roboto;
    --wb-expressive-introtext-font-size: 24px;
    --wb-expressive-introtext-font-weight: 400;
    --wb-expressive-introtext-line-height: 30px;
    --wb-expressive-introtext-color: rgba(0, 0, 0, 1);
    /** expressive-paragraph **/
    --wb-expressive-paragraph-font-family: Roboto;
    --wb-expressive-paragraph-font-size: 18px;
    --wb-expressive-paragraph-font-weight: 400;
    --wb-expressive-paragraph-line-height: 24px;
    --wb-expressive-paragraph-color: rgba(0, 0, 0, 1);
    /** productive-heading1 **/
    --wb-productive-heading1-font-family: Roboto;
    --wb-productive-heading1-font-size: 54px;
    --wb-productive-heading1-font-weight: 100;
    --wb-productive-heading1-line-height: 60px;
    --wb-productive-heading1-color: rgba(0, 94, 118, 1);
    /** productive-heading2 **/
    --wb-productive-heading2-font-family: Roboto;
    --wb-productive-heading2-font-size: 42px;
    --wb-productive-heading2-font-weight: 300;
    --wb-productive-heading2-line-height: 48px;
    --wb-productive-heading2-color: rgba(0, 94, 118, 1);
    /** productive-heading3 **/
    --wb-productive-heading3-font-family: Roboto;
    --wb-productive-heading3-font-size: 32px;
    --wb-productive-heading3-font-weight: 400;
    --wb-productive-heading3-line-height: 38px;
    --wb-productive-heading3-color: rgba(25, 70, 84, 1);
    /** productive-heading4 **/
    --wb-productive-heading4-font-family: Roboto;
    --wb-productive-heading4-font-size: 28px;
    --wb-productive-heading4-font-weight: 400;
    --wb-productive-heading4-line-height: 34px;
    --wb-productive-heading4-color: rgba(25, 70, 84, 1);
    /** productive-heading5 **/
    --wb-productive-heading5-font-family: Roboto;
    --wb-productive-heading5-font-size: 20px;
    --wb-productive-heading5-font-weight: 500;
    --wb-productive-heading5-line-height: 26px;
    --wb-productive-heading5-color: rgba(25, 70, 84, 1);
    /** productive-heading6 **/
    --wb-productive-heading6-font-family: Roboto;
    --wb-productive-heading6-font-size: 16px;
    --wb-productive-heading6-font-weight: 500;
    --wb-productive-heading6-line-height: 22px;
    --wb-productive-heading6-color: rgba(25, 70, 84, 1);
    /** productive-helper-label **/
    --wb-productive-helper-label-font-family: Roboto;
    --wb-productive-helper-label-font-size: 12px;
    --wb-productive-helper-label-font-weight: 400;
    --wb-productive-helper-label-line-height: 16px;
    --wb-productive-helper-label-color: rgba(8, 13, 13, 1);
    /** productive-paragraph-large **/
    --wb-productive-paragraph-large-font-family: Roboto;
    --wb-productive-paragraph-large-font-size: 16px;
    --wb-productive-paragraph-large-font-weight: 400;
    --wb-productive-paragraph-large-line-height: 22px;
    --wb-productive-paragraph-large-color: rgba(8, 13, 13, 1);
    /** productive-paragraph-small **/
    --wb-productive-paragraph-small-font-family: Roboto;
    --wb-productive-paragraph-small-font-size: 14px;
    --wb-productive-paragraph-small-font-weight: 400;
    --wb-productive-paragraph-small-line-height: 20px;
    --wb-productive-paragraph-small-color: rgba(8, 13, 13, 1);
}

/* close :root */

.wb-expressive-heading1 {
    font-family: var(--wb-expressive-heading1-font-family);
    font-size: var(--wb-expressive-heading1-font-size);
    font-weight: var(--wb-expressive-heading1-font-weight);
    line-height: var(--wb-expressive-heading1-line-height);
    color: var(--wb-expressive-heading1-color);
}

.wb-expressive-heading2 {
    font-family: var(--wb-expressive-heading2-font-family);
    font-size: var(--wb-expressive-heading2-font-size);
    font-weight: var(--wb-expressive-heading2-font-weight);
    line-height: var(--wb-expressive-heading2-line-height);
    color: var(--wb-expressive-heading2-color);
}

.wb-expressive-heading3 {
    font-family: var(--wb-expressive-heading3-font-family);
    font-size: var(--wb-expressive-heading3-font-size);
    font-weight: var(--wb-expressive-heading3-font-weight);
    line-height: var(--wb-expressive-heading3-line-height);
    color: var(--wb-expressive-heading3-color);
}

.wb-expressive-heading4 {
    font-family: var(--wb-expressive-heading4-font-family);
    font-size: var(--wb-expressive-heading4-font-size);
    font-weight: var(--wb-expressive-heading4-font-weight);
    line-height: var(--wb-expressive-heading4-line-height);
    color: var(--wb-expressive-heading4-color);
}

.wb-expressive-heading5 {
    font-family: var(--wb-expressive-heading5-font-family);
    font-size: var(--wb-expressive-heading5-font-size);
    font-weight: var(--wb-expressive-heading5-font-weight);
    line-height: var(--wb-expressive-heading5-line-height);
    color: var(--wb-expressive-heading5-color);
}

.wb-expressive-introtext {
    font-family: var(--wb-expressive-introtext-font-family);
    font-size: var(--wb-expressive-introtext-font-size);
    font-weight: var(--wb-expressive-introtext-font-weight);
    line-height: var(--wb-expressive-introtext-line-height);
    color: var(--wb-expressive-introtext-color);
}

.wb-expressive-paragraph {
    font-family: var(--wb-expressive-paragraph-font-family);
    font-size: var(--wb-expressive-paragraph-font-size);
    font-weight: var(--wb-expressive-paragraph-font-weight);
    line-height: var(--wb-expressive-paragraph-line-height);
    color: var(--wb-expressive-paragraph-color);
}

.wb-productive-heading1 {
    font-family: var(--wb-productive-heading1-font-family);
    font-size: var(--wb-productive-heading1-font-size);
    font-weight: var(--wb-productive-heading1-font-weight);
    line-height: var(--wb-productive-heading1-line-height);
    color: var(--wb-productive-heading1-color);
}

.wb-productive-heading2 {
    font-family: var(--wb-productive-heading2-font-family);
    font-size: var(--wb-productive-heading2-font-size);
    font-weight: var(--wb-productive-heading2-font-weight);
    line-height: var(--wb-productive-heading2-line-height);
    color: var(--wb-productive-heading2-color);
}

.wb-productive-heading3 {
    font-family: var(--wb-productive-heading3-font-family);
    font-size: var(--wb-productive-heading3-font-size);
    font-weight: var(--wb-productive-heading3-font-weight);
    line-height: var(--wb-productive-heading3-line-height);
    color: var(--wb-productive-heading3-color);
}

.wb-productive-heading4 {
    font-family: var(--wb-productive-heading4-font-family);
    font-size: var(--wb-productive-heading4-font-size);
    font-weight: var(--wb-productive-heading4-font-weight);
    line-height: var(--wb-productive-heading4-line-height);
    color: var(--wb-productive-heading4-color);
}

.wb-productive-heading5 {
    font-family: var(--wb-productive-heading5-font-family);
    font-size: var(--wb-productive-heading5-font-size);
    font-weight: var(--wb-productive-heading5-font-weight);
    line-height: var(--wb-productive-heading5-line-height);
    color: var(--wb-productive-heading5-color);
}

.wb-productive-heading6 {
    font-family: var(--wb-productive-heading6-font-family);
    font-size: var(--wb-productive-heading6-font-size);
    font-weight: var(--wb-productive-heading6-font-weight);
    line-height: var(--wb-productive-heading6-line-height);
    color: var(--wb-productive-heading6-color);
}

.wb-productive-helper-label {
    font-family: var(--wb-productive-helper-label-font-family);
    font-size: var(--wb-productive-helper-label-font-size);
    font-weight: var(--wb-productive-helper-label-font-weight);
    line-height: var(--wb-productive-helper-label-line-height);
    color: var(--wb-productive-helper-label-color);
}

.wb-productive-paragraph-large {
    font-family: var(--wb-productive-paragraph-large-font-family);
    font-size: var(--wb-productive-paragraph-large-font-size);
    font-weight: var(--wb-productive-paragraph-large-font-weight);
    line-height: var(--wb-productive-paragraph-large-line-height);
    color: var(--wb-productive-paragraph-large-color);
}

.wb-productive-paragraph-small {
    font-family: var(--wb-productive-paragraph-small-font-family);
    font-size: var(--wb-productive-paragraph-small-font-size);
    font-weight: var(--wb-productive-paragraph-small-font-weight);
    line-height: var(--wb-productive-paragraph-small-line-height);
    color: var(--wb-productive-paragraph-small-color);
}
