:root {
    --skeleton-block-default-padding: 2rem 0;
    --wb-background-white: #fff;
    --wb-black: #000;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        Roboto,
        Segoe UI,
        -apple-system,
        BlinkMacSystemFont,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    color: var(--default-dark, #000);
}

* {
    box-sizing: border-box;
}

html:has(.PortalPage) {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

html:has(.PortalPage) body {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
}

html:has(.PortalPage) body > #__next {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

html:has(.PortalPage) .BasePage {
    display: flex;
    flex: 1;
}

.wb-header__logo * {
    height: 100%;
    width: auto;
}

/* .inbeeld__webmap { */
.inbeeld__webmap .esri-widget--button,
.inbeeld__webmap .esri-menu,
.inbeeld__webmap .esri-popup__main-container,
.inbeeld__webmap .esri-popup .esri-popup__pointer-direction,
.inbeeld__webmap .esri-popup .esri-popup__button,
.inbeeld__webmap .esri-button,
.inbeeld__webmap .esri-input,
.inbeeld__webmap .esri-widget a {
    background-color: #fff;
    border: 1px solid var(--default-primary);
    color: var(--default-primary);
}

.inbeeld__webmap dialog .esri-input,
.inbeeld__webmap dialog .esri-widget--button {
    border: none;
    padding: 0.6rem;
}

.inbeeld__webmap .esri-attribution {
    border-width: 0;
    background-color: rgba(255, 255, 255, 0.8);
}

.inbeeld__webmap .esri-widget--button:hover,
.inbeeld__webmap .esri-menu li:focus,
.inbeeld__webmap .esri-menu li:hover {
    background-color: var(--default-secondary);
}

.inbeeld__webmap .esri-button:focus,
.inbeeld__webmap .esri-button:hover {
    color: var(--default-light);
}

.inbeeld__webmap .esri-search__input::-moz-placeholder,
.inbeeld__webmap .esri-search__input:-ms-input-placeholder,
.inbeeld__webmap .esri-search__input::-webkit-input-placeholder {
    color: #ccc;
}

.inbeeld__webmap .esri-ui-bottom-left .esri-search {
    order: 999;
}

.inbeeld__webmap
    .esri-view-width-xsmall
    .esri-expand__container.esri-expand__container--expanded {
    top: 7rem !important;
    height: fit-content !important;
}

.inbeeld__webmap
    .esri-view-width-xsmall
    .esri-expand--floating
    .esri-expand__container.esri-expand__container--expanded {
    top: unset !important;
}

.esri-hide {
    display: none;
}

.esri-ui-top-left .esri-search {
    order: -1;
}

.esri-ui-bottom-left .esri-search {
    order: 999;
}

.esri-scale-bar.esri-widget {
    z-index: 0;
}

a {
    color: inherit;
    text-decoration: none;
    color: var(--default-primary, var(--wb-blue));
}

.richtexteditor a {
    color: var(--default-primary, var(--wb-blue));
    text-decoration: underline;
}

.richtexteditor .richtext-image.right {
    margin-left: auto;
    display: flex;
}

.richtexteditor .richtext-image.left {
    margin-right: auto;
    display: flex;
}

.richtexteditor .richtext-image.center {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.richtexteditor iframe {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.bg-default-light {
    background-color: var(--default-light);
}

.bg-default-dark {
    background-color: var(--default-dark);
}

.bg-default-primary {
    background-color: var(--default-primary);
}

.bg-default-secondary {
    background-color: var(--default-secondary);
}

.inbeeld_webscene:not([open]) .esri-widget {
    display: none;
}

.wb-notification__text {
    flex: 1;
}

.PortalPage:has(dialog[data-cy='responselistDialog'][open]) #bookmarklist {
    width: 70%;
}
